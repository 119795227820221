<template>
  <div class="page">
    <section>
      <div class="box">
        <span class="title">非小细胞肺癌诊断治疗市场调研</span>
        <img class="banner" src="@image/dy-banner.png" alt="" />
        <span class="duration">问卷时长30分钟</span>
        <span class="money">¥100.00</span>
        <span class="prompt">答谢礼金(银行卡、微信红包、支付宝收款)</span>
      </div>
      <span
        @click="
          $router.push({ path: '/research-questionnaire-informedConsent' })
        "
        class="button"
        >我要参与</span
      >
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.page {
  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 550px;
      height: 620px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(3, 219, 198, 0.2);
      margin: 50px 0 30px 0;
      font-size: 24px;
      color: $--themeColor;
      padding: 49px 107px;
      box-sizing: border-box;
      > .title {
        font-weight: bold;
      }
      > .banner {
        width: 311px;
        height: 255px;
        object-fit: cover;
        margin: 46px 0 30px 0;
      }
      > .duration {
        font-size: 24px;
        color: #333;
        margin-bottom: 30px;
      }
      > .money {
        font-size: 36px;
        margin-bottom: 20px;
      }
      > .prompt {
        font-size: 14px;
        color: #8f8f8f;
      }
    }
    > .button {
      cursor: pointer;
      width: 384px;
      height: 46px;
      background: $--themeColor;
      border-radius: 23px;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
      line-height: 46px;
    }
  }
}
</style>